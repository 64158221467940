import { Component, OnInit, Renderer2 } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ENV } from "@app/env";
import { AuthService, TokenStore } from "@ruf/idp-auth";
import { SharedService, NotificationService } from "./services";
import { SessionManagementServiceProvider } from "./services/session-management-service";
import { BnNgIdleService } from "bn-ng-idle";
import { Location } from "@angular/common";
import { interval } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { LocalStorageService } from "./shared/model/LocalStorageService";

declare let pendo: any;
declare function pendoIntialize(apiKey, cookieCategory, scriptType): void;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: "Notification Search",
      url: "notification-search",
      icon: "notifications",
    },
    {
      title: "Subscriber Management",
      url: "subscriber",
      icon: "subscriber",
    },
    {
      title: "Event Registration",
      url: "event",
      icon: "event",
    },
    {
      title: "Dashboard",
      url: "dashboard",
      icon: "evdashboardent",
    },
    {
      title: "Glossary",
      url: "help",
      icon: "help",
    },
  ];

  isLoggedIn = false;
  username = "";
  lgNavOpen = true;
  mdNavOpen = false;
  isAdmin = false;
  loggedUser: any;
  loggedUserRole: any;
  ENV = ENV;
  environment: any;
  inProfilePage: boolean = false;


  public subscriberData: any;
  public eventsData: any;

  benRoleError = false;
  benViewError = false;
  authCallback = this.rufAuthService.tokens$;
  loggedUserEmail: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _router: Router,
    private _route: ActivatedRoute,
    private rufAuthService: AuthService,
    private _tokenStore: TokenStore,
    private sharedService: SharedService,
    private _notificationService: NotificationService,
    public sessionManagementService: SessionManagementServiceProvider,
    private idleService: BnNgIdleService,
    public location: Location,
    private spinner: NgxSpinnerService,
    public localStorageService: LocalStorageService,
    protected renderer: Renderer2
  ) {

    this._router.events.subscribe(() => {
      if (location.path() != "") {
        this.sharedService.route = location.path();
      }
    });

    interval(60000 * 2).subscribe((x) => {
      this.sharedService.invokeKeepAlive().subscribe();
    });

    this.initializeApp();
    if (this.rufAuthService.isLoggedIn) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

  }



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (
        !(
          this.sessionManagementService.isLoggedIn ||
          !this.sharedService.route ||
          this.sharedService.route.endsWith("authentication")
        )
      ) {
        if (ENV.USING_IDP) {
          this.rufAuthService.claims$.subscribe((claims) => {
            //this.sessionManagementService.userInformation = claims;
            this.sessionManagementService.isLoggedIn = true;
          });
        }
      }
    });
  }
  isLocalEnv(){
    return this.environment.includes("Local");
  }
  ngOnInit() {
    this.spinner.show();
    this.environment = ENV.environment;
    this.idleService
      .startWatching(ENV.SESSION_WARNING_DISPLAY_TIME)
      .subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          //&& this.sessionManagementService.isLoggedIn) {
          this.sessionManagementService.displaySessionTimeoutWarning = true;
        }
      });

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    if (this.rufAuthService.isLoggedIn) {
      this.isLoggedIn = true;

    } else {
      this.isLoggedIn = false;
    }

   
    this.authCallback.subscribe((response: any) => {
      this.sharedService.setIDToken(response.id_token);
      SharedService.idTokenKey = response.id_token;
      this.sharedService.setAccessToken(response.id_token);
        
      this._notificationService.gateway().subscribe(results => {
      
        localStorage.setItem("userDetails", JSON.stringify(results));
        this.sharedService.setLoginName(results.LoginName);
        this.sharedService.setBenPortalRole(results.APIDashBoardRole);
        this.loggedUser = results.LoginName;
        this.loggedUserRole = results.APIDashBoardRole;
        this.isLoggedIn = true;
        SharedService.subscriberInClaims = results.SubscriberGroups === 'WILDCARD' ? '' : results.SubscriberGroups;
        
        if(results.hasOwnProperty('BenPortalViewErrKey'))
        {
          SharedService.benViewError=true;
          this.benViewError=true;
        }
        if(results.hasOwnProperty('BenPortalRoleErrKey'))
        {SharedService.benRoleError=true;
          this.benRoleError=true;
        }

        /** One trust and Pendo Codes */
        this.onetrust();
        //Internal Firm
        if(ENV.pendoApiKey && results.Firm == "us_worldpay_fis_int") {
          pendoIntialize(ENV.pendoApiKey, null, 'text/javascript');
          try {           
            this.setPendoContext(results.EmailAddress);
              } catch (err) {
            }
          }

        // Loading One Trust scripts  
        if (ENV.production) {
            this.loadJsScriptProd();
          }
          else {
            this.loadJsScript();
          }
          //Pendo for External firm with Cookie
          if(ENV.pendoApiKey && results.Firm != "us_worldpay_fis_int") {
                pendoIntialize(ENV.pendoApiKey, ENV.performanceCookiedId, 'text/plain');
                this.headListener();
          }  

      });

    });
  
    this.highlightLastOpenedActiveTab();
    this.spinner.hide();
  }

  highlightLastOpenedActiveTab() {
    const lastKnownRoutingPath: string = localStorage.getItem("ruf-idp-auth-initial-path");
    if (lastKnownRoutingPath === "/help") {
      this.addRemoveClassForLastActiveTab("helpIcon");
    } else if (
      lastKnownRoutingPath === "/notification-search" ||
      lastKnownRoutingPath === "/notification-search/details"
    ) {
      //setting routing to parent notification section
      localStorage.setItem("ruf-idp-auth-initial-path", "/notification-search");
      this.addRemoveClassForLastActiveTab("notfIcon");
    } else if (lastKnownRoutingPath === "/dashboard") {
      this.addRemoveClassForLastActiveTab("dashboardIcon");
    } else if (this.checkIfInSubscriberSection(lastKnownRoutingPath)) {
      //setting routing to parent subscriber section
      localStorage.setItem("ruf-idp-auth-initial-path", "/subscriber/list");
      this.addRemoveClassForLastActiveTab("subscriberIcon");
    } else if (lastKnownRoutingPath === "/profile") {
      this.addRemoveClassForLastActiveTab("profilePage");
    }
  }

  checkIfInSubscriberSection(url: string): boolean {
    if (
      url === "/subscriber/add" ||
      url === "/subscriber/edit" ||
      url === "/subscriber/details" ||
      url === "/subscriber/registration" ||
      url === "/subscriber/contact" ||
      url === "/subscriber/preview" ||
      url === "/subscriber/list"
    ) {
      return true;
    } else {
      return false;
    }
  }

  addRemoveClassForLastActiveTab(elementId: string) {
    if (elementId == "profilePage") {
      const activeDiv = document.querySelector(".activeLink");
      activeDiv.classList.remove("activeLink");
      activeDiv.children[1].classList.remove("boldFont");
      this.inProfilePage = true;
    } else {
      const activeDiv = document.querySelector(".activeLink");
      activeDiv.classList.remove("activeLink");
      activeDiv.children[1].classList.remove("boldFont");
      document.getElementById(elementId).classList.add("activeLink");
      document.getElementById(elementId).children[1].classList.add("boldFont");
      this.inProfilePage = false;
    }
  }

  checkUserHasAccessToBenFrameworkVersion(): string {
    if (
      localStorage.getItem("eventDataV1") != null &&
      localStorage.getItem("eventDataV2") != null
    ) {
      this.localStorageService.version = "V1 & V2";
      return "V1 & V2";
    } else if (
      localStorage.getItem("eventDataV1") != null &&
      localStorage.getItem("eventDataV2") === null
    ) {
      this.localStorageService.version = "V1";
      return "V1";
    } else if (
      localStorage.getItem("eventDataV1") === null &&
      localStorage.getItem("eventDataV2") != null
    ) {
      this.localStorageService.version = "V2";
      return "V2";
    }
  }

  addRemoveClass(event) {
    if (this.inProfilePage) {
      event.currentTarget.classList.add("activeLink");
      event.currentTarget.children[1].classList.add("boldFont");
      this.inProfilePage = false;
    } else {
      const activeDiv = document.querySelector(".activeLink");
      activeDiv.classList.remove("activeLink");
      activeDiv.children[1].classList.remove("boldFont");
      event.currentTarget.classList.add("activeLink");
      event.currentTarget.children[1].classList.add("boldFont");
    }
  }

  showNotificationsPage(event) {
    this._router.navigate(["notification-search"]);
    this.addRemoveClass(event);
  }

  subscriberManagement(event): void {
    this._router.navigate(["subscriber/list"]);
    this.addRemoveClass(event);
  }

  subscriberManagement_a4e(event): void {
    this._router.navigate(["subscriber/search"]);
    this.addRemoveClass(event);
  }
  glossary(event): void {
    this._router.navigate(["help"]);
    this.addRemoveClass(event);
  }

  dashboard(event): void {
    this._router.navigate(["dashboard"]);
    this.addRemoveClass(event);
  }

  contactManagement(event): void {
    this._router.navigate(["contact/list"]);
    this.addRemoveClass(event);
  }

  eventRegistration(event) {
    this._router.navigate(["event"]);
    this.addRemoveClass(event);
  }

  licenseRequestForm(event) {
    this.addRemoveClass(event);
  }

  logoutConfirm() {
    this.isLoggedIn = false;
    let logouturl = localStorage.getItem('logoutUrl');
    localStorage.clear();
    sessionStorage.clear();
    
    setTimeout(() => {
      localStorage.setItem("isUserLoggedOut", "true");
      this.rufAuthService.logout(true).then(
        (value) => {
          setTimeout(() => {
            window.location.href = logouturl;
          });
        },
        (err) => { }
      );
    }, 10);
    
  }

  getLoggedUserSanitized(loggedUser: String): String {
    if (loggedUser != undefined || loggedUser != null || loggedUser != "") {
      return loggedUser.toString();
    } else {
      return "";
    }

  }
  profilePage(event): void {
    this._router.navigate(["profile"]);
    const activeDiv1 = document.querySelector(".activeLink");
    activeDiv1.classList.remove("activeLink");
    activeDiv1.children[1].classList.remove("boldFont");
    this.inProfilePage = true;
  }

  /** One Trsut Core logic part */
  public onetrust(): HTMLScriptElement{
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const script = this.renderer.createElement('script');
    var OneTrust = {
        dataSubjectParams: {        
                      id: userDetails.Guid,
                      isAnonymous: false,        
                      token : userDetails.OtCookieConsentToken
              }
         };  
    var str = " var OneTrust = "+JSON.stringify(OneTrust);
    script.type = 'text/javascript';    
    script.text = str;
    this.renderer.appendChild(document.head, script);
    return script;
}

  public loadJsScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.charset = "UTF-8";
    script.setAttribute('data-domain-script', '019058cb-773f-7f3e-8130-dc21dcd16ce0-test');
    script.text = function OptanonWrapper() { }
    this.renderer.appendChild(document.body, script);
    return script;
  }
  
  public loadJsScriptProd(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.charset = "UTF-8";
    script.setAttribute('data-domain-script', '019058cb-773f-7f3e-8130-dc21dcd16ce0');
    script.text = function OptanonWrapper() { }
    this.renderer.appendChild(document.body, script);
    return script;
  }

  /** Pendo Logic part */
  headListener = () => {
    const headElement = document.querySelector('head');
    const observer = new MutationObserver((mutatedEntries) => {
      mutatedEntries.forEach((mutation) => {
        const addedNodes = mutation.addedNodes;
        if (addedNodes.length > 0) {
          const addedElement: any = mutation.addedNodes[0];
          if (addedElement.id === 'pendo-script') {
            const pendoContext = () => {
              observer.disconnect();
              clearInterval(pendoInterval);              
              if (this.loggedUserEmail) {
                this.setPendoContext(this.loggedUserEmail);
              }               
            };
            const pendoInterval = setInterval(pendoContext, 2000);
          }

        }
      });
    });
    observer.observe(headElement, { childList: true });
  };

  setPendoContext(visitorId?) {
    if (visitorId) {
      pendo.initialize({
        visitor: {
          id: visitorId,
        },
        account: {
          id: ENV.pendoAccountId,
        },
      });
    } else {
      pendo.initialize({
        account: {
          id: ENV.pendoAccountId,
        },
      });
    }
  }


}
